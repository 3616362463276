import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BgHeader from "../components/BgHeader";
import { useStreamers } from "../context/StreamersContext";
import { BsChevronRight } from "react-icons/bs";
import { AiFillYoutube, AiFillFacebook } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import logo from "../images/mango-ave-no-name.png";

const StreamerPage = () => {
  const { id } = useParams();
  const { streamers, fetchStreamers } = useStreamers();
  const [loading, setLoading] = useState(true);
  const [randomStreamer, setRandomStreamer] = useState([]);
  const [selectedStreamer, setSelectedStreamer] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (id) fetchStreamers().finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (streamers.length) {
      const findStreamer = streamers.find((item) => item.id === id);
      const result = streamers
        .filter((item) => item.id !== id && item.onboarded)
        .slice(0, 3)
        .map(function () {
          return this.splice(Math.floor(Math.random() * this.length), 1)[0];
        }, streamers.slice());

      setRandomStreamer(result);
      setSelectedStreamer(findStreamer);
    }
  }, [streamers]);

  return (
    <div>
      <BgHeader />

      <div className="container" style={{ marginTop: "-15vh" }}>
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mb-0">Please wait..</p>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-8">
              <div
                className="p-3 rounded"
                style={{ backgroundColor: "#0f3460" }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={selectedStreamer?.photoURL || logo}
                    alt="Avatar"
                    className="avatar"
                    style={{ width: 100, height: 100 }}
                  />

                  <div className="d-flex flex-column ml-3">
                    <h5 className="mb-0">
                      {[
                        selectedStreamer?.firstname,
                        selectedStreamer?.lastname,
                      ].join(" ")}
                    </h5>
                    <p className="mb-0 small">{`@${selectedStreamer?.alias}`}</p>
                    <p className="mb-0 small">{`${selectedStreamer?.followerCount} followers`}</p>
                  </div>
                </div>

                <div className="mt-5">
                  <h5 className="mb-0">Games Playing</h5>
                  <hr style={{ borderTop: "1px solid #fff" }} />
                  {selectedStreamer?.gamesPlaying?.map((game, i) => (
                    <span key={i} className="badge badge-light mr-2">
                      {game}
                    </span>
                  ))}
                </div>
                <div className="mt-5">
                  <h5 className="mb-0">Featured Streams</h5>
                  <hr style={{ borderTop: "1px solid #fff" }} />
                </div>
                <div className="mt-5">
                  <h5 className="mb-0">Sponsors and Stats</h5>
                  <hr style={{ borderTop: "1px solid #fff" }} />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="p-3 rounded"
                style={{ backgroundColor: "#0f3460" }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0">Meet Streamers</h5>
                  <Link
                    to="/streamers"
                    className="btn btn-outline-light btn-sm"
                  >
                    Show More
                  </Link>
                </div>
                <hr style={{ borderTop: "1px solid #fff" }} />

                <ul className="list-group list-group-flush">
                  {randomStreamer.map((item, index) => (
                    <li
                      className="list-group-item"
                      key={index}
                      style={{
                        padding: 0,
                        backgroundColor: "transparent",
                        border: "none",
                        marginBottom: "1rem",
                      }}
                    >
                      <Link
                        to={`/streamers/${item?.id}`}
                        className="text-white streamer"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <img
                            src={item?.photoURL || logo}
                            alt="Avatar"
                            className="avatar"
                            style={{ width: 50, height: 50 }}
                          />

                          <div
                            className="d-flex align-items-center justify-content-between streamerInfo"
                            style={{ flex: 1 }}
                          >
                            <div className="d-flex flex-column ml-3">
                              <p className="mb-0">
                                {[item?.firstname, item?.lastname].join(" ")}
                              </p>
                              <p className="mb-0 small">{`@${item?.alias}`}</p>
                            </div>

                            <BsChevronRight className="arrow-right d-none" />
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div
                className="p-3 mt-3 rounded"
                style={{ backgroundColor: "#0f3460" }}
              >
                <h5 className="mb-0">Let's get social</h5>
                <hr style={{ borderTop: "1px solid #fff" }} />

                <a
                  href="https://www.youtube.com/channel/UC9-OBcY3YoqWqiW2lYFAk2g"
                  className="d-flex mb-3 text-white"
                  target="_blank"
                >
                  <AiFillYoutube style={{ fontSize: 24 }} />
                  <p className="mb-0 ml-3">YouTube</p>
                </a>
                <a
                  href="https://www.facebook.com/getmangoave"
                  className="d-flex mb-3 text-white"
                  target="_blank"
                >
                  <AiFillFacebook style={{ fontSize: 24 }} />
                  <p className="mb-0 ml-3">Facebook</p>
                </a>
                <a
                  href="https://discord.gg/vfYagJ4CqE"
                  className="d-flex mb-3 text-white"
                  target="_blank"
                >
                  <FaDiscord style={{ fontSize: 24 }} />
                  <p className="mb-0 ml-3">Discord</p>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StreamerPage;
