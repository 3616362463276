import React, { useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import LocationSearchInput from "../locationSearchInput/LocationSearchInput";

const UpdateBasicInfo = ({ setIsUpdate }) => {
  const addressRef = useRef();
  const { userInfo, updateUserInfo, setUserInfo, updateEmail } = useAuth();
  const [state, setState] = useState(userInfo);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("handleSubmit", state);

    if (!state.address) {
      addressRef.current.classList.add("is-invalid");
      return;
    } else {
      addressRef.current.classList.remove("is-invalid");
    }

    const promises = [];
    setLoading(true);
    setError("");

    const payload = {
      firstname: state.firstname,
      lastname: state.lastname,
      address: state.address,
      email: state.email,
      phone: state.phone,
    };
    promises.push(updateUserInfo(state.id, payload));
    if (state.email !== userInfo.email) {
      promises.push(updateEmail(state.email));
    }

    Promise.all(promises)
      .then(() => {
        setUserInfo(state);
        setIsUpdate(false);
      })
      .catch(() => {
        setError("Failed to update basic info.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {loading && (
          <div className="alert alert-info" role="alert">
            <div className="d-flex align-items-center justify-content-center">
              <div className="spinner-border mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              Updating..
            </div>
          </div>
        )}
        {error && (
          <div className="alert alert-danger text-center" role="alert">
            {error}
          </div>
        )}

        {!loading && (
          <div className="text-right">
            <button
              type="button"
              className="btn btn-outline-light btn-sm"
              onClick={() => setIsUpdate(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary btn-sm ml-3" type="submit">
              Save
            </button>
          </div>
        )}

        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="firstname">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstname"
              name="firstname"
              value={state.firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              name="lastname"
              value={state.lastname}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <LocationSearchInput
          address={state.address}
          setState={setState}
          addressRef={addressRef}
        />
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={state.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              value={state.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateBasicInfo;
