import React, { useState, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import * as BsIcons from "react-icons/bs";
import { datalist } from "../../utils/dropdownList";

const UpdateStreamInfo = ({ setIsUpdate }) => {
  const streamingPlatformsRef = useRef();
  const interestRef = useRef();
  const gamesPlayingRef = useRef();
  const productsRef = useRef();
  const entertainmentRef = useRef();

  const { userInfo, updateUserInfo, setUserInfo } = useAuth();
  const [state, setState] = useState(userInfo);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "contentStream") {
      switch (value) {
        case "Games":
          setState((prevState) => ({
            ...prevState,
            [name]: value,
            products: [],
            entertainment: [],
          }));
          break;
        case "Online Selling":
          setState((prevState) => ({
            ...prevState,
            [name]: value,
            gamesPlaying: [],
            entertainment: [],
          }));
          break;
        case "Entertainment":
          setState((prevState) => ({
            ...prevState,
            [name]: value,
            gamesPlaying: [],
            products: [],
          }));
          break;
        default:
          setState((prevState) => ({
            ...prevState,
            [name]: value,
            gamesPlaying: [],
            products: [],
            entertainment: [],
          }));
      }
    } else if (name === "followerCount") {
      const count =
        value && value > 0 ? parseInt(value.replace(/^0+/, "")) : "";
      setState((prevState) => ({
        ...prevState,
        ["followerCount"]: count,
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleAddMultiple = (field) => {
    let value,
      prevData = state[field];

    if (field === "streamingPlatforms") {
      value = streamingPlatformsRef.current.value;
    } else if (field === "interest") {
      value = interestRef.current.value;
    } else if (field === "gamesPlaying") {
      value = gamesPlayingRef.current.value;
    } else if (field === "products") {
      value = productsRef.current.value;
    } else if (field === "entertainment") {
      value = entertainmentRef.current.value;
    }

    if (value) {
      const data = [...prevData, value];
      setState((prevState) => ({ ...prevState, [field]: data }));

      if (field === "streamingPlatforms") {
        streamingPlatformsRef.current.value = "";
        streamingPlatformsRef.current.classList.remove("is-invalid");
      } else if (field === "interest") {
        interestRef.current.value = "";
        interestRef.current.classList.remove("is-invalid");
      } else if (field === "gamesPlaying") {
        gamesPlayingRef.current.value = "";
        gamesPlayingRef.current.classList.remove("is-invalid");
      } else if (field === "products") {
        productsRef.current.value = "";
        productsRef.current.classList.remove("is-invalid");
      } else if (field === "entertainment") {
        entertainmentRef.current.value = "";
        entertainmentRef.current.classList.remove("is-invalid");
      }
    }
  };

  const handleRemoveMultiple = (field, index) => {
    let prevData = state[field];

    const data = prevData.filter((_, idx) => idx !== index);
    setState((prevState) => ({ ...prevState, [field]: data }));
  };

  const checkContentStream = (contentStream) => {
    let isValid = false;
    switch (contentStream) {
      case "Games":
        isValid = state.gamesPlaying.length > 0;
        break;
      case "Online Selling":
        isValid = state.products.length > 0;
        break;
      case "Entertainment":
        isValid = state.entertainment.length > 0;
        break;
      default:
        isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("handleSubmit", state);

    if (state.interest.length > 0) {
      if (state.streamingPlatforms.length > 0) {
        if (checkContentStream(state.contentStream)) {
          const promises = [];
          setLoading(true);
          setError("");

          const payload = {
            alias: state.alias,
            streamChannelUrl: state.streamChannelUrl,
            followerCount: state.followerCount,
            streamingPlatforms: state.streamingPlatforms,
            interest: state.interest,
            contentStream: state.contentStream,
            gamesPlaying: state.gamesPlaying,
            products: state.products,
            entertainment: state.entertainment,
          };
          promises.push(updateUserInfo(state.id, payload));

          Promise.all(promises)
            .then(() => {
              setUserInfo(state);
              setIsUpdate(false);
            })
            .catch(() => {
              setError("Failed to update basic info.");
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          if (state.contentStream === "Games") {
            gamesPlayingRef.current.classList.add("is-invalid");
          } else if (state.contentStream === "Online Selling") {
            productsRef.current.classList.add("is-invalid");
          } else if (state.contentStream === "Entertainment") {
            entertainmentRef.current.classList.add("is-invalid");
          }
        }
      } else {
        streamingPlatformsRef.current.classList.add("is-invalid");
      }
    } else {
      interestRef.current.classList.add("is-invalid");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && (
        <div className="alert alert-info" role="alert">
          <div className="d-flex align-items-center justify-content-center">
            <div className="spinner-border mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            Updating..
          </div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger text-center" role="alert">
          {error}
        </div>
      )}

      {!loading && (
        <div className="text-right">
          <button
            type="button"
            className="btn btn-outline-light btn-sm"
            onClick={() => setIsUpdate(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary btn-sm ml-3" type="submit">
            Save
          </button>
        </div>
      )}

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="alias">Streamer Name</label>
            <input
              type="text"
              className="form-control"
              id="alias"
              name="alias"
              onChange={handleChange}
              value={state.alias}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="streamChannelUrl">Streamer Channel Url</label>
            <input
              type="url"
              className="form-control"
              id="streamChannelUrl"
              name="streamChannelUrl"
              onChange={handleChange}
              value={state.streamChannelUrl}
              required
              aria-describedby="streamChannelUrlHelp"
            />
            <small id="streamChannelUrlHelp" className="form-text">
              {"https://www.facebook.com/mangoave | https://www.youtube.com"}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="followerCount">Channel Follower Count</label>
            <input
              type="text"
              className="form-control"
              id="followerCount"
              name="followerCount"
              onChange={handleChange}
              value={state.followerCount}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="streamingPlatforms">Streaming Platforms</label>
            <div className="form-row">
              <div className="col-10">
                <select
                  className="form-control"
                  id="streamingPlatforms"
                  name="streamingPlatforms"
                  ref={streamingPlatformsRef}
                  aria-describedby="streamingPlatformsHelp"
                >
                  <option value="">Select platform</option>
                  <option value="Youtube">Youtube</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Twitch">Twitch</option>
                </select>
                <small id="streamingPlatformsHelp" className="form-text">
                  Please click plus button to add streaming platform
                </small>
                <div className="invalid-feedback">
                  Select streaming platform atleast one.
                </div>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={() => handleAddMultiple("streamingPlatforms")}
                >
                  <BsIcons.BsPlus />
                </button>
              </div>
            </div>
            <div className="mt-2">
              {state.streamingPlatforms.map((item, index) => (
                <div
                  className="badge badge-primary mr-2 mb-2 text-uppercase"
                  key={(item, index)}
                >
                  <div className="d-flex align-items-center p-1">
                    <span>{item}</span>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light ml-2"
                      style={{ padding: "0.1rem 0.6rem" }}
                      onClick={() =>
                        handleRemoveMultiple("streamingPlatforms", index)
                      }
                    >
                      <BsIcons.BsTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="interest">Interest</label>
            <div className="form-row">
              <div className="col-10">
                {/* <input
                  type="text"
                  className="form-control"
                  id="interest"
                  ref={interestRef}
                  aria-describedby="interestHelp"
                /> */}
                <select
                  className="form-control"
                  id="interest"
                  ref={interestRef}
                  aria-describedby="interestHelp"
                >
                  {datalist.interests.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>

                <small id="interestHelp" className="form-text">
                  Please click plus button to add interest (Gaming | Streaming)
                </small>
                <div className="invalid-feedback">
                  Add interest atleast one.
                </div>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={() => handleAddMultiple("interest")}
                >
                  <BsIcons.BsPlus />
                </button>
              </div>
            </div>
            <div className="mt-2">
              {state.interest.map((item, index) => (
                <div
                  className="badge badge-primary mr-2 mb-2 text-uppercase"
                  key={item}
                >
                  <div className="d-flex align-items-center p-1">
                    <span>{item}</span>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light ml-2"
                      style={{ padding: "0.1rem 0.6rem" }}
                      onClick={() => handleRemoveMultiple("interest", index)}
                    >
                      <BsIcons.BsTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="contentStream">Stream Content</label>
            <select
              className="form-control"
              id="contentStream"
              name="contentStream"
              onChange={handleChange}
              value={state.contentStream}
              required
            >
              <option value="">Select content</option>
              <option value="Games">Games</option>
              <option value="Online Selling">Online Selling</option>
              <option value="Entertainment">Entertainment</option>
            </select>
          </div>
          {state.contentStream === "Games" && (
            <GamesContent
              gamesPlayingRef={gamesPlayingRef}
              gamesPlaying={state.gamesPlaying}
              handleAddMultiple={handleAddMultiple}
              handleRemoveMultiple={handleRemoveMultiple}
            />
          )}
          {state.contentStream === "Online Selling" && (
            <OnlineSellingContent
              productsRef={productsRef}
              products={state.products}
              handleAddMultiple={handleAddMultiple}
              handleRemoveMultiple={handleRemoveMultiple}
            />
          )}
          {state.contentStream === "Entertainment" && (
            <EntertainmentContent
              entertainmentRef={entertainmentRef}
              entertainment={state.entertainment}
              handleAddMultiple={handleAddMultiple}
              handleRemoveMultiple={handleRemoveMultiple}
            />
          )}
        </div>
      </div>
    </form>
  );
};

const GamesContent = ({
  gamesPlayingRef,
  gamesPlaying,
  handleAddMultiple,
  handleRemoveMultiple,
}) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="gamesPlaying">Games Playing</label>
        <div className="form-row">
          <div className="col-10">
            <select
              className="form-control"
              id="gamesPlaying"
              name="gamesPlaying"
              ref={gamesPlayingRef}
              aria-describedby="gamesPlayingHelp"
            >
              <option value="">Select games</option>
              <option value="Valorant">Valorant</option>
              <option value="PUBG">PUBG</option>
              <option value="CoD">CoD</option>
              <option value="LoL">LoL</option>
              <option value="Mobile Legend">Mobile Legend</option>
              <option value="CyberPunk 2077">CyberPunk 2077</option>
            </select>
            <small id="gamesPlayingHelp" className="form-text">
              Please click plus button to add game
            </small>
            <div className="invalid-feedback">Select game atleast one.</div>
          </div>
          <div className="col-2">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => handleAddMultiple("gamesPlaying")}
            >
              <BsIcons.BsPlus />
            </button>
          </div>
        </div>
        <div className="mt-2">
          {gamesPlaying.map((item, index) => (
            <div
              className="badge badge-primary mr-2 mb-2 text-uppercase"
              key={index}
            >
              <div className="d-flex align-items-center p-1">
                <span>{item}</span>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-light ml-2"
                  style={{ padding: "0.1rem 0.6rem" }}
                  onClick={() => handleRemoveMultiple("gamesPlaying", index)}
                >
                  <BsIcons.BsTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const OnlineSellingContent = ({
  productsRef,
  products,
  handleAddMultiple,
  handleRemoveMultiple,
}) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="products">Products</label>
        <div className="form-row">
          <div className="col-10">
            {/* <input
              type="text"
              className="form-control"
              id="products"
              ref={productsRef}
              aria-describedby="productsHelp"
            /> */}

            <select
              className="form-control"
              id="products"
              ref={productsRef}
              aria-describedby="productsHelp"
            >
              {datalist.products.map((product, index) => (
                <option key={index}>{product}</option>
              ))}
            </select>
            <small id="productsHelp" className="form-text">
              Please click plus button to add product
            </small>
            <div className="invalid-feedback">Add product atleast one.</div>
          </div>
          <div className="col-2">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => handleAddMultiple("products")}
            >
              <BsIcons.BsPlus />
            </button>
          </div>
        </div>
        <div className="mt-2">
          {products.map((item, index) => (
            <div
              className="badge badge-primary mr-2 mb-2 text-uppercase"
              key={index}
            >
              <div className="d-flex align-items-center p-1">
                <span>{item}</span>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-light ml-2"
                  style={{ padding: "0.1rem 0.6rem" }}
                  onClick={() => handleRemoveMultiple("products", index)}
                >
                  <BsIcons.BsTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const EntertainmentContent = ({
  entertainmentRef,
  entertainment,
  handleAddMultiple,
  handleRemoveMultiple,
}) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="entertainment">What can you do?</label>
        <div className="form-row">
          <div className="col-10">
            {/* <input
              type="text"
              className="form-control"
              id="entertainment"
              ref={entertainmentRef}
              aria-describedby="entertainmentHelp"
            /> */}

            <select
              className="form-control"
              id="entertainment"
              ref={entertainmentRef}
              aria-describedby="entertainmentHelp"
            >
              {datalist.entertainments.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
            <small id="entertainmentHelp" className="form-text">
              Please click plus button to add entertainment
            </small>
            <div className="invalid-feedback">
              Add entertainment atleast one.
            </div>
          </div>
          <div className="col-2">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => handleAddMultiple("entertainment")}
            >
              <BsIcons.BsPlus />
            </button>
          </div>
        </div>
        <div className="mt-2">
          {entertainment.map((item, index) => (
            <div
              className="badge badge-primary mr-2 mb-2 text-uppercase"
              key={index}
            >
              <div className="d-flex align-items-center p-1">
                <span>{item}</span>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-light ml-2"
                  style={{ padding: "0.1rem 0.6rem" }}
                  onClick={() => handleRemoveMultiple("entertainment", index)}
                >
                  <BsIcons.BsTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UpdateStreamInfo;
