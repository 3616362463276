import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";

const ProfileUrlModal = ({ children }) => {
  const { saveProfileImage, getProfileImageUrl, currentUser } = useAuth();
  const fileRef = useRef();
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState({ success: false, msg: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setResponse({ success: false, msg: "" });
  };

  const handleSubmit = () => {
    if (fileRef?.current?.files) {
      const fileType = fileRef.current.files[0].type;

      if (fileType === "image/jpeg" || fileType === "image/png") {
        setLoading(true);
        setResponse({ success: false, msg: "" });
        saveProfileImage(fileRef.current.files[0])
          .then(async (res) => {
            // console.log("saveProfileImage", res);
            await getProfileImageUrl(currentUser);
            setLoading(false);
            setResponse({
              success: true,
              msg: "Uploaded successfully!",
            });
            handleClose();
          })
          .catch((err) => {
            setLoading(false);
            setResponse({ success: false, msg: err.message });
          });
      } else {
        alert("Upload valid images: Only PNG and JPEG are allowed");
      }
    }
  };

  return (
    <>
      {!children && (
        <button
          className="btn btn-sm btn-outline-light text-uppercase"
          onClick={handleShow}
        >
          Change Photo
        </button>
      )}
      {children && (
        <button className="btn" onClick={handleShow}>
          {children}
        </button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-dark">Change Profile Photo</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {response.msg && (
            <div
              className={`alert ${
                response.success ? "alert-success" : "alert-danger"
              }`}
              role="alert"
            >
              {response.msg}
            </div>
          )}

          <div className="text-dark">
            <form>
              <div className="form-group">
                <input
                  type="file"
                  className="form-control-file"
                  ref={fileRef}
                  accept="image/png, image/jpeg"
                  //   onChange={(e) => console.log(e.target.files[0])}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn btn-dark"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Updating.." : "Update"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileUrlModal;
