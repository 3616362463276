import React from "react";
import queryString from "query-string";
import { auth } from "../firebase";
import VerifyEmail from "../components/EmailHandler/VerifyEmail";
import ResetPassword from "../components/EmailHandler/ResetPassword";
import { useHistory } from "react-router";

const EmailHandlerPage = (props) => {
  const history = useHistory();
  const parsed_search = queryString.parse(props.location.search);

  const { mode, oobCode: actionCode } = parsed_search;
  const values = { auth, actionCode };

  if (!auth || !actionCode) history.push("/");

  switch (mode) {
    case "resetPassword":
      return <ResetPassword {...values} />;
    case "recoverEmail":
      // Display email recovery handler and UI.
      //handleRecoverEmail(auth, actionCode,);
      return null;
    case "verifyEmail":
      return <VerifyEmail {...values} />;
    default:
      return null;
  }
};

export default EmailHandlerPage;
