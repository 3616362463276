import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";

import "react-phone-number-input/style.css";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";

const UpdatePaymentInfo = ({ setIsUpdate }) => {
  const { userInfo, updateUserInfo, setUserInfo } = useAuth();
  const [state, setState] = useState(userInfo);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      payment: { ...prevState.payment, [name]: value },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("handleSubmit", state);

    const { gcash, coins_ph } = state.payment;
    let _continue = true;
    if (gcash) _continue = isValidPhoneNumber(gcash);
    if (coins_ph) _continue = isValidPhoneNumber(coins_ph);

    if (_continue) {
      const promises = [];
      setLoading(true);
      setError("");

      const payload = {
        payment: { ...state.payment },
      };
      promises.push(updateUserInfo(state.id, payload));

      Promise.all(promises)
        .then(() => {
          setUserInfo(state);
          setIsUpdate(false);
        })
        .catch(() => {
          setError("Failed to update payment info");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && (
        <div className="alert alert-info" role="alert">
          <div className="d-flex align-items-center justify-content-center">
            <div className="spinner-border mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            Updating..
          </div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger text-center" role="alert">
          {error}
        </div>
      )}

      {!loading && (
        <div className="text-right">
          <button
            type="button"
            className="btn btn-outline-light btn-sm"
            onClick={() => setIsUpdate(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary btn-sm ml-3" type="submit">
            Save
          </button>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="paypal">Paypal</label>
        <input
          type="email"
          className="form-control"
          id="paypal"
          name="paypal"
          value={state.payment.paypal}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="gcash">Gcash</label>
        <Input
          id="gcash"
          className={`form-control ${
            state.payment.gcash
              ? isValidPhoneNumber(state.payment.gcash)
                ? "is-valid"
                : "is-invalid"
              : ""
          }`}
          country="PH"
          international
          withCountryCallingCode
          value={state.payment.gcash}
          onChange={(phone) =>
            setState((prevState) => ({
              ...prevState,
              payment: { ...prevState.payment, gcash: phone || "" },
            }))
          }
        />
      </div>
      <div className="form-group">
        <label htmlFor="coins_ph">Coins Ph</label>
        <Input
          id="coins_ph"
          className={`form-control ${
            state.payment.coins_ph
              ? isValidPhoneNumber(state.payment.coins_ph)
                ? "is-valid"
                : "is-invalid"
              : ""
          }`}
          country="PH"
          international
          withCountryCallingCode
          value={state.payment.coins_ph}
          onChange={(phone) =>
            setState((prevState) => ({
              ...prevState,
              payment: { ...prevState.payment, coins_ph: phone || "" },
            }))
          }
        />
      </div>
    </form>
  );
};

export default UpdatePaymentInfo;
