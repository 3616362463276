import React, { useRef, useState } from "react";
import * as BsIcons from "react-icons/bs";
import { datalist } from "../../utils/dropdownList";

const StreamInfo = (props) => {
  const {
    alias,
    streamChannelUrl,
    followerCount,
    streamingPlatforms,
    contentStream,
    gamesPlaying,
    products,
    entertainment,
    handleChange,
    setState,
    prevstep,
    handleSubmitData,
  } = props;
  const streamingPlatformsRef = useRef();
  const gamesPlayingRef = useRef();
  const productsRef = useRef();
  const entertainmentRef = useRef();

  const [loading, setLoading] = useState(false);

  const [isOthers, setIsOthers] = React.useState(false);
  const [othersField, setOthersField] = React.useState("");
  React.useEffect(() => {
    if (!isOthers) setOthersField("");
  }, [isOthers]);

  const handleAddMultiple = (field) => {
    let value, prevData;
    if (field === "streamingPlatforms") {
      value = streamingPlatformsRef.current.value;
      prevData = streamingPlatforms;
    } else if (field === "gamesPlaying") {
      value = gamesPlayingRef.current.value;
      prevData = gamesPlaying;
    } else if (field === "products") {
      value = productsRef.current.value;
      prevData = products;
    } else if (field === "entertainment") {
      value = entertainmentRef.current.value;
      prevData = entertainment;
    }

    if (value) {
      if (value.toLowerCase() === "others") {
        setIsOthers(true);
      } else {
        setIsOthers(false);

        const data = [...prevData, value];
        setState((prevState) => ({
          ...prevState,
          [field]: data.filter((item, index) => data.indexOf(item) === index),
        }));

        if (field === "streamingPlatforms") {
          streamingPlatformsRef.current.value = "";
          streamingPlatformsRef.current.classList.remove("is-invalid");
        } else if (field === "gamesPlaying") {
          gamesPlayingRef.current.value = "";
          gamesPlayingRef.current.classList.remove("is-invalid");
        } else if (field === "products") {
          productsRef.current.value = "";
          productsRef.current.classList.remove("is-invalid");
        } else if (field === "entertainment") {
          entertainmentRef.current.value = "";
          entertainmentRef.current.classList.remove("is-invalid");
        }
      }
    } else {
      setIsOthers(false);
    }
  };

  const handleRemoveMultiple = (field, index) => {
    let prevData;
    if (field === "streamingPlatforms") {
      prevData = streamingPlatforms;
    } else if (field === "gamesPlaying") {
      prevData = gamesPlaying;
    } else if (field === "products") {
      prevData = products;
    } else if (field === "entertainment") {
      prevData = entertainment;
    }

    const data = prevData.filter((_, idx) => idx !== index);
    setState((prevState) => ({ ...prevState, [field]: data }));
  };

  const checkContentStream = (contentStream) => {
    let isValid = false;
    switch (contentStream) {
      case "Games":
        isValid = gamesPlaying.length > 0;
        break;
      case "Online Selling":
        isValid = products.length > 0;
        break;
      case "Entertainment":
        isValid = entertainment.length > 0;
        break;
      default:
        isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (contentStream) {
      if (checkContentStream(contentStream)) {
        if (streamingPlatforms.length > 0) {
          setLoading(true);
          handleSubmitData(() => {
            setLoading(false);
          });
        } else {
          streamingPlatformsRef.current.classList.add("is-invalid");
        }
      } else {
        if (contentStream === "Games") {
          gamesPlayingRef.current.classList.add("is-invalid");
        } else if (contentStream === "Online Selling") {
          productsRef.current.classList.add("is-invalid");
        } else if (contentStream === "Entertainment") {
          entertainmentRef.current.classList.add("is-invalid");
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="alias">Streamer Name</label>
        <input
          type="text"
          className="form-control"
          id="alias"
          name="alias"
          value={alias || ""}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="streamChannelUrl">Streamer Channel Url</label>
        <input
          type="url"
          className="form-control"
          id="streamChannelUrl"
          name="streamChannelUrl"
          value={streamChannelUrl}
          onChange={handleChange}
          required
          aria-describedby="streamChannelUrlHelp"
          pattern="https://www\.youtube\.com\/(.+)|https://www\.facebook\.com\/(.+)|https://www\.twitch\.tv\/(.+)"
        />
        <small id="streamChannelUrlHelp" className="form-text">
          {
            "https://www.facebook.com/mangoave | https://www.youtube.com/mangoave | https://www.twitch.tv/mangoavegaming"
          }
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="followerCount">Follower Count</label>
        <input
          type="number"
          className="form-control"
          id="followerCount"
          name="followerCount"
          value={followerCount}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="contentStream">Stream Content</label>
        <select
          className="form-control"
          id="contentStream"
          name="contentStream"
          value={contentStream}
          onChange={(e) => {
            handleChange(e);
            setIsOthers(false);
          }}
          required
        >
          <option value="">Select content</option>
          <option value="Games">Games</option>
          <option value="Online Selling">Online Selling</option>
          <option value="Entertainment">Entertainment</option>
        </select>
      </div>

      {contentStream === "Games" && (
        <div className="form-group">
          <label htmlFor="gamesPlaying">Games Playing</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" id="basic-addon2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="others"
                    checked={isOthers}
                    onChange={(e) => setIsOthers(e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="others">
                    Others
                  </label>
                </div>
              </div>
            </div>

            {isOthers ? (
              <>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter other game and click + button."
                  value={othersField}
                  onChange={(e) => setOthersField(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      const data = [...gamesPlaying, othersField.toLowerCase()];
                      setState((prevState) => ({
                        ...prevState,
                        gamesPlaying: data.filter(
                          (item, index) => data.indexOf(item) === index
                        ),
                      }));

                      setOthersField("");
                    }}
                  >
                    <BsIcons.BsPlus />
                  </button>
                </div>
              </>
            ) : (
              <>
                <select
                  className="form-control"
                  id="gamesPlaying"
                  name="gamesPlaying"
                  ref={gamesPlayingRef}
                  aria-describedby="gamesPlayingHelp"
                >
                  <option value="">Select games</option>
                  <option value="Valorant">Valorant</option>
                  <option value="PUBG">PUBG</option>
                  <option value="CoD">CoD</option>
                  <option value="LoL">LoL</option>
                  <option value="Mobile Legend">Mobile Legend</option>
                  <option value="CyberPunk 2077">CyberPunk 2077</option>
                </select>
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleAddMultiple("gamesPlaying")}
                  >
                    <BsIcons.BsPlus />
                  </button>
                </div>
              </>
            )}
          </div>
          <small id="gamesPlayingHelp" className="form-text">
            Please click plus button to add game
          </small>
          <div className="invalid-feedback">Select game atleast one.</div>

          {gamesPlaying && gamesPlaying.length > 0 && (
            <div className="mt-2 border rounded p-2">
              <p className="small text-muted mb-1">List of selected games</p>

              {gamesPlaying.map((item, index) => (
                <div
                  className="badge badge-primary mr-2 mb-2 text-uppercase"
                  key={index}
                >
                  <div className="d-flex align-items-center p-1">
                    <span>{item}</span>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light ml-2"
                      style={{ padding: "0.1rem 0.6rem" }}
                      onClick={() =>
                        handleRemoveMultiple("gamesPlaying", index)
                      }
                    >
                      <BsIcons.BsTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {contentStream === "Online Selling" && (
        <div className="form-group">
          <label htmlFor="products">Products</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" id="basic-addon2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="others"
                    checked={isOthers}
                    onChange={(e) => setIsOthers(e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="others">
                    Others
                  </label>
                </div>
              </div>
            </div>

            {isOthers ? (
              <>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter other product and click + button."
                  value={othersField}
                  onChange={(e) => setOthersField(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      const data = [...products, othersField.toLowerCase()];
                      setState((prevState) => ({
                        ...prevState,
                        products: data.filter(
                          (item, index) => data.indexOf(item) === index
                        ),
                      }));

                      setOthersField("");
                    }}
                  >
                    <BsIcons.BsPlus />
                  </button>
                </div>
              </>
            ) : (
              <>
                <select
                  className="form-control"
                  id="products"
                  ref={productsRef}
                  aria-describedby="productsHelp"
                >
                  <option value="">Select product</option>
                  {datalist.products.map((product, index) => (
                    <option key={index} value={product}>
                      {product}
                    </option>
                  ))}
                </select>
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleAddMultiple("products")}
                  >
                    <BsIcons.BsPlus />
                  </button>
                </div>
              </>
            )}
          </div>
          <small id="productsHelp" className="form-text">
            Please click plus button to add product
          </small>
          <div className="invalid-feedback">Add product atleast one.</div>

          {products && products.length > 0 && (
            <div className="mt-2 border rounded p-2">
              <p className="small text-muted mb-1">List of selected products</p>

              {products.map((item, index) => (
                <div
                  className="badge badge-primary mr-2 mb-2 text-uppercase"
                  key={index}
                >
                  <div className="d-flex align-items-center p-1">
                    <span>{item}</span>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light ml-2"
                      style={{ padding: "0.1rem 0.6rem" }}
                      onClick={() => handleRemoveMultiple("products", index)}
                    >
                      <BsIcons.BsTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {contentStream === "Entertainment" && (
        <div className="form-group">
          <label htmlFor="entertainment">What can you do?</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text" id="basic-addon2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="others"
                    checked={isOthers}
                    onChange={(e) => setIsOthers(e.target.checked)}
                  />
                  <label className="custom-control-label" htmlFor="others">
                    Others
                  </label>
                </div>
              </div>
            </div>

            {isOthers ? (
              <>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter other entertainment and click + button."
                  value={othersField}
                  onChange={(e) => setOthersField(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      const data = [
                        ...entertainment,
                        othersField.toLowerCase(),
                      ];
                      setState((prevState) => ({
                        ...prevState,
                        entertainment: data.filter(
                          (item, index) => data.indexOf(item) === index
                        ),
                      }));

                      setOthersField("");
                    }}
                  >
                    <BsIcons.BsPlus />
                  </button>
                </div>
              </>
            ) : (
              <>
                <select
                  className="form-control"
                  id="entertainment"
                  ref={entertainmentRef}
                  aria-describedby="entertainmentHelp"
                >
                  <option value="">Select entertainment</option>
                  {datalist.entertainments.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleAddMultiple("entertainment")}
                  >
                    <BsIcons.BsPlus />
                  </button>
                </div>
              </>
            )}
          </div>
          <small id="entertainmentHelp" className="form-text">
            Please click plus button to add entertainment
          </small>
          <div className="invalid-feedback">Add entertainment atleast one.</div>

          {entertainment && entertainment.length > 0 && (
            <div className="mt-2 border rounded p-2">
              <p className="small text-muted mb-1">
                List of selected entertainments
              </p>

              {entertainment.map((item, index) => (
                <div
                  className="badge badge-primary mr-2 mb-2 text-uppercase"
                  key={index}
                >
                  <div className="d-flex align-items-center p-1">
                    <span>{item}</span>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light ml-2"
                      style={{ padding: "0.1rem 0.6rem" }}
                      onClick={() =>
                        handleRemoveMultiple("entertainment", index)
                      }
                    >
                      <BsIcons.BsTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="form-group">
        <label htmlFor="streamingPlatforms">Streaming Platforms</label>
        <div className="input-group">
          <select
            className="form-control"
            id="streamingPlatforms"
            name="streamingPlatforms"
            ref={streamingPlatformsRef}
            aria-describedby="streamingPlatformsHelp"
          >
            <option value="">Select platform</option>
            <option value="Youtube">Youtube</option>
            <option value="Facebook">Facebook</option>
            <option value="Twitch">Twitch</option>
          </select>
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => handleAddMultiple("streamingPlatforms")}
            >
              <BsIcons.BsPlus />
            </button>
          </div>
        </div>
        <small id="streamingPlatformsHelp" className="form-text">
          Please click plus button to add streaming platform
        </small>
        <div className="invalid-feedback">
          Select streaming platform atleast one.
        </div>

        {streamingPlatforms && streamingPlatforms.length > 0 && (
          <div className="mt-2 border rounded p-2">
            <p className="small text-muted mb-1">
              List of selected streaming platform
            </p>

            {streamingPlatforms.map((item, index) => (
              <div
                className="badge badge-primary mr-2 mb-2 text-uppercase"
                key={(item, index)}
              >
                <div className="d-flex align-items-center p-1">
                  <span>{item}</span>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-light ml-2"
                    style={{ padding: "0.1rem 0.6rem" }}
                    onClick={() =>
                      handleRemoveMultiple("streamingPlatforms", index)
                    }
                  >
                    <BsIcons.BsTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="mt-5 d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-light text-uppercase"
          onClick={prevstep}
        >
          back
        </button>
        <button className="btn btn-primary text-uppercase">
          {loading ? "saving..." : "save"}
        </button>
      </div>
    </form>
  );
};

export default StreamInfo;
