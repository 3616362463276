import React from "react";
import styled from "styled-components";
import Img from "../images/profile-bg.jpg";

const BgHeader = () => {
  return <ProfileBanner img={Img} />;
};

const ProfileBanner = styled.div`
  background-image: ${(props) => `url(${props.img})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 30vh;
`;
export default BgHeader;
