import React, { useEffect, useState } from "react";
import BgHeader from "../components/BgHeader";
import CurrencyFormatter from "../components/CurrencyFormatter/CurrencyFormatter";
import { useAuth } from "../context/AuthContext";
import { getWallet, cashoutSendRequest } from "../utils/helper";

const WalletPage = () => {
  const { userInfo, getUserToken } = useAuth();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    async function fetchWallet(id) {
      try {
        const token = await getUserToken();

        const wallet = await getWallet(token, id).then(async (res) => ({
          response: await res.json(),
          status: res.status,
        }));
        if (wallet?.status === 200) {
          setData(wallet?.response);
        } else {
          // @todo add more flag
          switch (wallet?.response?.message) {
            case "ERROR_WALLET":
              setErrMsg(wallet?.response?.message);
              break;
            default:
              break;
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    }
    fetchWallet(userInfo.id);
  }, []);

  const showMessage = (errMsg) => {
    switch (errMsg) {
      case "ERROR_WALLET":
        return "Wallet unavailable.";
      default:
        return "";
    }
  };

  const handleCashoutSendRequest = async () => {
    if (window.confirm("Submit cash out request?")) {
      setIsSubmit(true);
      try {
        const token = await getUserToken();

        const req = await cashoutSendRequest(token, userInfo.id).then(
          async (res) => ({
            response: await res.json(),
            status: res.status,
          })
        );
        if (req?.status === 200) {
          alert("Cashout request successfully submit.");
        } else {
          alert("Error in sending cashout request. Please try again");
        }
      } catch (error) {
        console.log("error", error);
        alert("Error in sending cashout request. Please try again");
      } finally {
        setIsSubmit(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="container">
        <div className="text-center mt-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mb-0">Please wait..</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <BgHeader />
      <div className="container" style={{ marginTop: "-15vh" }}>
        <div
          className="w-100 p-3 rounded"
          style={{
            maxWidth: 500,
            margin: "0 auto",
            backgroundColor: "#0f3460",
          }}
        >
          <h1 className="text-center mb-3">My Wallet</h1>

          {errMsg && (
            <div>
              {errMsg && (
                <div className="alert alert-danger" role="alert">
                  {showMessage(errMsg)}
                </div>
              )}
            </div>
          )}

          <div className="row">
            <div className="col-md-8 text-center text-md-left">
              <p className="mb-0">Current Wallet</p>
              <h1 className="display-5">
                <CurrencyFormatter
                  currency={"PHP"}
                  amount={data?.current_wallet || 0}
                />
              </h1>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-between">
                <small className="mb-0 text-muted">Total Earnings</small>
                <h4 className="mb-0">
                  <CurrencyFormatter
                    currency={"PHP"}
                    amount={data?.total_earnings || 0}
                  />
                </h4>
              </div>
              <div className="d-flex justify-content-between">
                <small className="mb-0 text-muted">Total Revenues</small>
                <h4 className="mb-0">
                  <CurrencyFormatter
                    currency={"PHP"}
                    amount={data?.total_revenues || 0}
                  />
                </h4>
              </div>
              <div className="d-flex justify-content-between">
                <small className="mb-0 text-muted">Total Rewards</small>
                <h4 className="mb-0">
                  <CurrencyFormatter
                    currency={"PHP"}
                    amount={data?.total_rewards || 0}
                  />
                </h4>
              </div>
              <div className="d-flex justify-content-between">
                <small className="mb-0 text-muted">Total Streams</small>
                <h4 className="mb-0">{data?.total_streams || 0}</h4>
              </div>
            </div>
          </div>
          <button
            className="btn btn-block btn-primary mt-3"
            disabled={
              (!data?.cashout_ready & data?.cashout_requested) ||
              isSubmit ||
              false
            }
            onClick={handleCashoutSendRequest}
          >
            Cash-out
          </button>
        </div>
      </div>
    </>
  );
};

export default WalletPage;
