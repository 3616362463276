import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import ProgressBar from "../components/progressBar/ProgressBar";

import BasicInfo from "../components/onboarding/BasicInfo";
import ContactInfo from "../components/onboarding/ContactInfo";
import StreamInfo from "../components/onboarding/StreamInfo";

import { sendWelcomeEmail } from "../utils/helper";

const OnboardingPage = () => {
  const { currentUser, setUserInfo, updateUserInfo, userInfo } = useAuth();
  const [step, setStep] = useState(1);
  const [state, setState] = useState(userInfo);
  const history = useHistory();

  const nextstep = () => setStep(step + 1);
  const prevstep = () => setStep(step - 1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "contentStream") {
      if (value === "Games") {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          products: [],
          entertainment: [],
        }));
      } else if (value === "Online Selling") {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          gamesPlaying: [],
          entertainment: [],
        }));
      } else if (value === "Entertainment") {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          gamesPlaying: [],
          products: [],
        }));
      }
    } else if (name === "followerCount") {
      const count =
        value && value > 0 ? parseInt(value.replace(/^0+/, "")) : "";
      setState((prevState) => ({
        ...prevState,
        ["followerCount"]: count,
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmitData = async (cb) => {
    await updateUserInfo(currentUser.uid, {
      ...state,
      onboarded: true,
      photoURL: "",
    });
    await setUserInfo((prevState) => ({
      ...prevState,
      ...state,
      onboarded: true,
    }));
    await sendWelcomeEmail({
      email: currentUser.email,
      displayName: currentUser.displayName,
    });
    cb();
    history.push("/");
  };

  const values = {
    ...state,
    setState,
    handleChange,
    currentUser,
    nextstep,
    prevstep,
    handleSubmitData,
  };

  switch (step) {
    case 1:
      return (
        <OnboardingLayout step={step}>
          <BasicInfo {...values} />
        </OnboardingLayout>
      );
    case 2:
      return (
        <OnboardingLayout step={step}>
          <ContactInfo {...values} />
        </OnboardingLayout>
      );
    case 3:
      return (
        <OnboardingLayout step={step}>
          <StreamInfo {...values} />
        </OnboardingLayout>
      );
    default:
      return null;
  }
};

const OnboardingLayout = ({ children, step }) => {
  const showTitle = (mystep) => {
    switch (mystep) {
      case 1:
        return <h4 className="text-center">Hello! Let's get to know you</h4>;
      case 2:
        return <h4 className="text-center">Now, How do we contact you?</h4>;
      case 3:
        return <h4 className="text-center">Finally, Stream time!</h4>;
      default:
        return null;
    }
  };

  const stepPercent = (step / 3) * 100;

  return (
    <div className="container mt-5 w-100" style={{ maxWidth: 500 }}>
      {showTitle(step)}
      <ProgressBar stepPercent={stepPercent} />
      {children}
    </div>
  );
};

export default OnboardingPage;
