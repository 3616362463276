import React from "react";
import styled from "styled-components";

const ProgressBar = ({ stepPercent }) => {
  return (
    <ProgressbarWrapper className="progress mb-3">
      <ProgressBarInner
        className="progress-bar"
        role="progressbar"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        percent={stepPercent}
      ></ProgressBarInner>
    </ProgressbarWrapper>
  );
};

const ProgressbarWrapper = styled.div`
  height: 0.2rem;
  border-radiues: 0;
`;

const ProgressBarInner = styled.div`
  background-color: #ff6712;
  width: ${(props) => props.percent}%;
`;

export default ProgressBar;
