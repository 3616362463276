import React from "react";
import { useAuth } from "../../context/AuthContext";
import logo from "../../images/mango-ave-no-name.png";

const Avatar = ({ active }) => {
  const { userInfo } = useAuth();

  return (
    <img
      src={userInfo?.photoURL || logo}
      alt={`photo`}
      className="img-fluid rounded-circle"
      style={{ width: active ? 80 : 40, height: active ? 80 : 40 }}
    />
  );
};

export default Avatar;
