import React, { useContext, useState } from "react";
import { getStreamers } from "../utils/helper";

const StreamersContext = React.createContext(null);

export function useStreamers() {
  return useContext(StreamersContext);
}

export function StreamersProvider({ children }) {
  const [streamers, setStreamers] = useState([]);

  function fetchStreamers() {
    return getStreamers()
      .then((response) => response.json())
      .then((data) => {
        setStreamers(data.results);
      })
      .catch((err) => console.log("err: ", err));
  }

  const value = { streamers, fetchStreamers };
  return (
    <StreamersContext.Provider value={value}>
      {children}
    </StreamersContext.Provider>
  );
}
