import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { getNews } from "../../utils/helper";
import { Link } from "react-router-dom";

const CarouselContainer = () => {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNews()
      .then((response) => response.json())
      .then((data) => {
        setImage(data.results);
      })
      .catch((err) => console.log("err: ", err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "80vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: "relative", minHeight: "80vh" }}>
      {image.length && (
        <Link
          to="/adCenter"
          className="btn text-white py-2 px-3"
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: 99999,
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          Get Ads
        </Link>
      )}

      <Carousel>
        {image.map((item, index) => {
          if (!item.media.url) return null;
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={item.media.url}
                alt={item.media.url}
                style={{ maxHeight: "80vh" }}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselContainer;
