import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser, userInfo } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          if (userInfo?.onboarded) return <Component {...props} />;
          else return <Redirect to="/" />;
        }

        return <Redirect to="/login" />;
      }}
    ></Route>
  );
}
