import React, { useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Link, useHistory } from "react-router-dom";

import firebase from "firebase";
import "firebase/auth";
import { StyledFirebaseAuth } from "react-firebaseui";

const LoginPage = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    setError("");
    setLoading(true);
    try {
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch (err) {
      console.log("err", err);
      setError(err?.message || "Failed to create an account");
    } finally {
      setLoading(false);
    }
  }

  if (currentUser) history.push("/");
  return (
    <div className="container pt-5">
      <div
        className="w-100 bg-white text-dark p-3 rounded"
        style={{ maxWidth: 400, margin: "0 auto" }}
      >
        <h3 className="text-center">Log In</h3>
        <FirebaseUI />

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <div className="text-center">
          <small className="text-muted">or use your account</small>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              ref={emailRef}
              type="email"
              className="form-control"
              id="email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              ref={passwordRef}
              type="password"
              className="form-control"
              id="password"
              required
            />
            <p className="text-right">
              <Link to="resetpw">Forgot Password?</Link>
            </p>
          </div>
          <button disabled={loading} className="mt-3 btn btn-block btn-primary">
            {loading ? "Signing in.." : "Log In"}
          </button>
        </form>

        <p className="mt-3">
          Need an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;

const FirebaseUI = () => {
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        return false;
      },
    },
  };
  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  );
};
