import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import PrivateRoute from "./PrivateRoute";
import useWindowSize from "./hooks/useWindowSize";

import Navigation from "./components/navigation/Navigation";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import StreamersPage from "./pages/StreamersPage";
import StreamerPage from "./pages/StreamerPage";
import EmailHandlerPage from "./pages/EmailHandlerPage";
import SignupPage from "./pages/SignupPage";
import LogoutPage from "./pages/LogoutPage";
import ProfilePage from "./pages/ProfilePage";
import AdsStreamPage from "./pages/AdsStreamPage";
import WalletPage from "./pages/WalletPage";
import PlayToEarnGames from "./pages/PlayToEarnGames";
import PageNotFound from "./pages/PageNotFound";
import ComingSoonPage from "./pages/ComingSoonPage";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const size = useWindowSize();
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (size?.width) {
      if (size.width > 768) setActive(true);
      else setActive(false);
    }
  }, [size]);

  const showSidebar = () => setActive(!active);
  return (
    <div className="app">
      <Navigation showSidebar={showSidebar} active={active} />
      <MainWrapper active={active}>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/signup" component={SignupPage} />
          <Route exact path="/resetpw" component={ResetPasswordPage} />
          <Route exact path="/streamers" component={StreamersPage} />
          <Route exact path="/streamers/:id" component={StreamerPage} />
          <Route exact path="/emailHandler" component={EmailHandlerPage} />

          <Route path="/playToEarnGames" component={PlayToEarnGames} />
          <Route path="/marketplace" component={ComingSoonPage} />
          <Route path="/scholarships" component={ComingSoonPage} />
          <Route path="/esports" component={ComingSoonPage} />

          <PrivateRoute exact path="/logout" component={LogoutPage} />
          <PrivateRoute exact path="/profile" component={ProfilePage} />
          <PrivateRoute exact path="/adCenter" component={AdsStreamPage} />
          <PrivateRoute exact path="/wallet" component={WalletPage} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </MainWrapper>
    </div>
  );
}

const MainWrapper = styled.div`
  transition: 350ms ease-in-out;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
`;

export default App;
