import React, { useState, useRef } from "react";
import BgHeader from "../components/BgHeader";
import { useAuth } from "../context/AuthContext";
import { getAddStream } from "../utils/helper";
import { datalist } from "../utils/dropdownList";

const AdsStreamPage = () => {
  const [data, setData] = useState({
    user_stream_link: "",
    game_playing: "",
  });
  const [adStreamUrl, setAdStreamUrl] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo, getUserToken } = useAuth();

  const adStreamUrlRef = useRef();
  const userStreamLinkRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "user_stream_link") {
      if (!validURL(value)) {
        userStreamLinkRef.current.className = "form-control is-invalid";
      } else {
        userStreamLinkRef.current.className = "form-control is-valid";
      }
    }

    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleGetAds = async (e) => {
    e.preventDefault();
    setErrMsg("");

    if (validURL(data.user_stream_link)) {
      try {
        setLoading(true);
        const token = await getUserToken();

        const addStream = await getAddStream(token, userInfo.id, data).then(
          async (res) => ({
            response: await res.json(),
            status: res.status,
          })
        );

        if (addStream.status === 200) {
          setAdStreamUrl(addStream?.response?.ad_stream_url);
        } else {
          // @todo add more flag
          switch (addStream?.response?.message) {
            case "ERROR_STREAM_LIMIT":
              setErrMsg(addStream?.response?.message);
              break;
            case "ERROR_NO_ADS":
              setErrMsg(addStream?.response?.message);
              break;
            default:
              break;
          }

          adStreamUrlRef.current.value = "";
          setAdStreamUrl("");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCopy = () => {
    adStreamUrlRef.current.select();
    document.execCommand("copy");
  };

  const showMessage = (errMsg) => {
    switch (errMsg) {
      case "ERROR_STREAM_LIMIT":
        return "You have exceeded stream limit for the day.";
      case "ERROR_NO_ADS":
        return "No ads available currently.";
      default:
        return "";
    }
  };

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  return (
    <>
      <BgHeader />
      <div className="container" style={{ marginTop: "-15vh" }}>
        <div
          className="w-100 p-3 rounded"
          style={{
            maxWidth: 500,
            margin: "0 auto",
            backgroundColor: "#0f3460",
          }}
        >
          <h2 className="text-center mb-4">Earn while you stream now...</h2>

          {errMsg && (
            <div className="alert alert-danger" role="alert">
              {showMessage(errMsg)}
              <button
                type="button"
                className="close"
                onClick={() => setErrMsg("")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <form onSubmit={handleGetAds}>
            <div className="form-group">
              <label htmlFor="user_stream_link">
                Paste your Livestream link here
              </label>
              <input
                ref={userStreamLinkRef}
                type="url"
                className="form-control"
                id="user_stream_link"
                name="user_stream_link"
                value={data.user_stream_link}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="game_playing">
                What content you are streaming today?
              </label>
              <select
                className="form-control"
                id="game_playing"
                name="game_playing"
                value={data.game_playing}
                onChange={handleChange}
                required
              >
                <option value="">Please select content </option>
                {datalist.content_streaming.map((el, i) => (
                  <option key={i} value={el.value}>
                    {el.title}
                  </option>
                ))}
              </select>
              {/* <input
                type="text"
                className="form-control"
                id="game_playing"
                name="game_playing"
                value={data.game_playing}
                onChange={handleChange}
                required
                placeholder={"Game-CoD, Online Selling, Entertainment"}
              /> */}
            </div>
            <button
              className="btn btn-outline-primary btn-block"
              disabled={loading}
            >
              {loading ? "loading.." : "Give me ads!"}
            </button>
          </form>

          {adStreamUrl && (
            <div className="input-group mt-5">
              <input
                id="adStreamUrl"
                type="text"
                className="form-control"
                placeholder="Wait for your ad stream link here.."
                ref={adStreamUrlRef}
                defaultValue={adStreamUrl}
                readOnly
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary text-uppercase"
                  type="button"
                  onClick={handleCopy}
                >
                  copy
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdsStreamPage;
