import React, { useRef } from "react";
import LocationSearchInput from "../locationSearchInput/LocationSearchInput";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";

const ContactInfo = (props) => {
  const addressRef = useRef();
  const phoneRef = useRef();
  const { email, phone, address, prevstep, nextstep, setState } = props;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (address) {
      addressRef.current.classList.remove("is-invalid");
      if (isValidPhoneNumber(phone)) {
        phoneRef.current.classList.remove("is-invalid");
        nextstep();
      } else {
        phoneRef.current.classList.add("is-invalid");
      }
    } else {
      addressRef.current.classList.add("is-invalid");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          defaultValue={email}
          readOnly
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone Number</label>
        <Input
          ref={phoneRef}
          id="coins_ph"
          className={`form-control ${
            phone ? (isValidPhoneNumber(phone) ? "is-valid" : "is-invalid") : ""
          }`}
          country="PH"
          international
          withCountryCallingCode
          value={phone}
          onChange={(num) =>
            setState((prevState) => ({
              ...prevState,
              phone: num,
            }))
          }
          required
        />
      </div>

      <LocationSearchInput {...props} addressRef={addressRef} />

      <div className="mt-5 d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-light text-uppercase"
          onClick={prevstep}
        >
          back
        </button>
        <button className="btn btn-primary text-uppercase">next</button>
      </div>
    </form>
  );
};

export default ContactInfo;
