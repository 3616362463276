import React, { useContext, useState, useEffect } from "react";
import { auth, db, storage } from "../firebase";
import { sendverification } from "../utils/helper";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  function saveProfileImage(file) {
    return storage.ref(`users/${currentUser.uid}/profile.jpg`).put(file);
  }

  function getProfileImageUrl(user) {
    if (user) {
      storage
        .ref(`users/${user.uid}/profile.jpg`)
        .getDownloadURL()
        .then((imgUrl) => {
          if (imgUrl) {
            // console.log("imgUrl", imgUrl);
            //@todo update firestore
            updateUserInfo(user.uid, { photoURL: imgUrl })
              .then(() => {
                setUserInfo((prev) => ({ ...prev, photoURL: imgUrl }));
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    localStorage.removeItem("idToken");
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function sendEmailVerificationLink() {
    return currentUser.sendEmailVerification();
  }

  async function getUserInfo(user) {
    if (user) {
      return db
        .collection("streamers")
        .doc(user.uid)
        .get()
        .then(async (doc) => doc.data());
    }
    return null;
  }

  function setToken(user) {
    if (user) {
      user
        .getIdToken(true)
        .then((idToken) => {
          localStorage.setItem("idToken", idToken);
        })
        .catch((err) => console.log(err));
    }
  }

  function updateUserInfo(id, payload) {
    return db.collection("/streamers").doc(id).update(payload);
  }

  async function getUserToken() {
    let token = await currentUser.getIdToken(true);
    return token;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const url = process.env.REACT_APP_ROOT_URL || "http://localhost:3000";

      if (user) {
        //get or create user collection
        const usersDocRef = db.collection("users").doc(user.uid);

        try {
          //get user in users collection
          const doc = await usersDocRef.get();
          if (doc.exists) {
            const docData = doc.data();
            //check the user type
            if (docData.type === "streamer") {
              const info = await getUserInfo(user);
              if (info) setUserInfo(info);
            } else {
              alert(
                "Access Denied! The page you're trying to access has restricted access. Please refer to the system administrator"
              );
              logout();
            }
          } else {
            //create user
            await usersDocRef.set({
              type: "streamer",
              displayName: user?.displayName || "",
              email: user?.email || "",
            });

            //send verification email and create streamer via api with streamer response return
            await sendverification({
              user: {
                displayName: user?.displayName || "",
                email: user?.email || "",
                emailVerified: user?.emailVerified || "",
                uid: user?.uid || "",
                type: "streamer",
              },
              url: `${url}/emailHandler?mode=verifyEmail`,
            });

            //get streamer info
            const info = await getUserInfo(user);
            setUserInfo(info);
          }
        } catch (error) {
          console.log("Error getting document:", error);
          logout();
        }

        // await getProfileImageUrl(user);
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
        setUserInfo(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    userInfo,
    setUserInfo,
    getUserInfo,
    saveProfileImage,
    login,
    setToken,
    signup,
    logout,
    updateEmail,
    updatePassword,
    resetPassword,
    sendEmailVerificationLink,
    getProfileImageUrl,
    updateUserInfo,
    getUserToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
