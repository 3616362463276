import React from "react";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";

export const GoogleButton = (props) => {
  return (
    <button
      {...props}
      style={{
        backgroundColor: "rgb(215, 61, 50)",
        borderColor: "rgb(215, 61, 50)",
        color: "#fff",
      }}
    >
      <AiIcons.AiFillGoogleSquare /> Google
    </button>
  );
};

export const EmailPwButton = (props) => {
  return (
    <button
      {...props}
      style={{
        backgroundColor: "red",
        borderColor: "red",
        color: "#fff",
      }}
    >
      <BsIcons.BsAt /> Email
    </button>
  );
};
