const API_URL =
  process.env.REACT_APP_BASE_URL ||
  "https://asia-east2-mangoave-dev-test.cloudfunctions.net";
const HEADERS = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export const newStreamerData = {
  onboarded: false,
  id: "",
  firstname: "",
  lastname: "",
  birthdate: "",
  gender: "",
  interest: [],
  phoneNo: "",
  email: "",
  address: "",
  city: "",
  country: "",
  streamerName: "",
  streamChannelUrl: "",
  followerCount: "",
  streamingPlatforms: [],
  contentStream: "",
  gamesPlaying: [],
  products: [],
  entertainment: [],
};

export const getWallet = (token, id) => {
  return fetch(`${API_URL}/api/streamer/wallet/${id}`, {
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAddStream = (token, id, payload) => {
  return fetch(`${API_URL}/api/streams/${id}`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${token}`,
    },
  });
};

export const processOnboarding = (payload) => {
  return fetch(`${API_URL}/api/streamer/onboard`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${localStorage?.getItem("idToken")}`,
    },
  });
};

export const getNews = () => {
  return fetch(`${API_URL}/api/pages/content/news`);
};

export const getStreamers = () => {
  return fetch(`${API_URL}/api/pages/content/streamers`);
};

export const resendVerificationEmail = (payload) => {
  return fetch(`${API_URL}/resendVerificationEmail`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const sendWelcomeEmail = (payload) => {
  return fetch(`${API_URL}/sendWelcomeEmail`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const sendverification = (payload) => {
  return fetch(`${API_URL}/sendVerificationEmail`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

export const cashoutSendRequest = (token, streamerid) => {
  return fetch(`${API_URL}/api/streamer/cashout/${streamerid}`, {
    method: "post",
    body: JSON.stringify({}),
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${token}`,
    },
  });
};
