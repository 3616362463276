import React from "react";

const PageNotFound = () => {
  return (
    <div className="container mt-5">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h1>404</h1>
        <h3>Page Not Found!</h3>
        <p>Oops! We can't seem to find the page you're looking for.</p>
      </div>
    </div>
  );
};

export default PageNotFound;
