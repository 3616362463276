import React from "react";
import comingSoon from "../images/comingSoon.svg";

const ComingSoonPage = () => {
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <center>
        <img
          className="img-fluid"
          src={comingSoon}
          alt="comingSoon"
          style={{ width: 400 }}
        />
      </center>
      <h1 className="text-center mt-3">Coming Soon..</h1>
    </div>
  );
};

export default ComingSoonPage;
