import React, { useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Link, useHistory } from "react-router-dom";

const ResetPasswordPage = () => {
  const emailRef = useRef();

  const { currentUser, resetPassword } = useAuth();
  const history = useHistory();

  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMsg("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMsg("Check your inbox for further instructions");
    } catch (err) {
      console.log("err", err);
      setError(err.message || "Failed to reset password");
    }

    setLoading(false);
  }

  if (currentUser) history.push("/");
  return (
    <div className="container mt-5 d-flex align-items-center justify-content-center">
      <div
        className="w-100 bg-white text-dark p-3 rounded"
        style={{ maxWidth: "400px" }}
      >
        <h3 className="text-center">Forget Password</h3>

        {msg && (
          <div className="alert alert-info" role="alert">
            {msg}
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              ref={emailRef}
              type="email"
              className="form-control"
              id="email"
              required
            />
          </div>
          <button disabled={loading} className="mt-3 btn btn-block btn-primary">
            {loading ? "Resetting password.." : "Reset Password"}
          </button>
        </form>

        <p className="m-0 text-center mt-3">
          Already have an account? <Link to="/login">Log In</Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
