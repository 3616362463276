import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

function ResetPassword({ auth, actionCode }) {
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const history = useHistory();

  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (errMsg?.code === "password_changed") {
      setTimeout(function () {
        history.push("/login");
      }, 5000);
    }
  }, [errMsg, history]);

  function handleResetPassword(auth, actionCode, newPassword) {
    auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        // const accountEmail = email;
        // Save the new password.
        auth
          .confirmPasswordReset(actionCode, newPassword)
          .then((resp) => {
            // Password reset has been confirmed and new password updated.
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // await auth.signInWithEmailAndPassword(accountEmail, newPassword);
            // history.push("/");

            //show success message and redirect to login page
            //@todo call api send reset confirmation email
            setErrMsg({
              type: "alert alert-success",
              message: "password_changed",
              code: "password_changed",
            });
          })
          .catch((error) => {
            console.log("error", error);
            setErrMsg({
              type: "alert alert-danger",
              message: error.message,
              code: error.code,
            });
          });
      })
      .catch((error) => {
        console.log(error.code);
        setErrMsg({
          type: "alert alert-danger",
          message: error.message,
          code: error.code,
        });
      })
      .finally(() => setLoading(false));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrMsg("");
    setLoading(true);

    if (newPassRef.current.value !== confirmPassRef.current.value) {
      setErrMsg({
        type: "alert alert-danger",
        message: "Mismatch password.",
        code: "",
      });
      setLoading(false);
      return;
    }

    handleResetPassword(auth, actionCode, newPassRef.current.value);
  }

  function handleShowMessage(errMsg) {
    switch (errMsg.code) {
      case "auth/expired-action-code":
        return (
          <span>
            &nbsp;Send&nbsp;<Link to="/resetpw">reset password link</Link> again
          </span>
        );
      case "auth/invalid-action-code":
        return (
          <span>
            &nbsp;Send&nbsp;<Link to="/resetpw">reset password link</Link> again
          </span>
        );
      case "password_changed":
        return (
          <span>
            <strong>Password Changed!</strong>&nbsp;
            <span>
              You can now <Link to="/login">sign in</Link> with you new password
            </span>
          </span>
        );
      default:
        return <span>errMsg.message</span>;
    }
  }

  return (
    <div className="container mt-5">
      <div className="w-100 m-auto" style={{ maxWidth: 450 }}>
        <h1 className="text-center mb-3">Reset Password</h1>
        {errMsg?.type && (
          <div className={errMsg.type} role="alert">
            {handleShowMessage(errMsg)}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="newPassword">New password</label>
            <input
              ref={newPassRef}
              type="password"
              className="form-control"
              id="newPassword"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm password</label>
            <input
              ref={confirmPassRef}
              type="password"
              className="form-control"
              id="confirmPassword"
              required
            />
          </div>
          <button className="btn btn-block btn-primary" disabled={loading}>
            {loading ? (
              <div className="spinner-border ml-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <span>Reset</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
