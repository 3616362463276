import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import logo from "../../images/mangoAveLogo.png";
import "./navigation.css";
import Avatar from "../Avatar/Avatar";
import useWindowSize from "../../hooks/useWindowSize";
import ProfileUrlModal from "../Modal/ProfileUrlModal";

const Navigation = ({ showSidebar, active }) => {
  const { currentUser, userInfo, logout } = useAuth();

  const windowSize = useWindowSize();

  const sidebarStyle = () => {
    if (windowSize.width <= 360) {
      return { width: active ? 600 : 60 };
    } else {
      return { width: active ? 250 : 60 };
    }
  };

  return (
    <div className="navigation h-100 d-flex flex-column" style={sidebarStyle()}>
      <ul className="border-bottom py-2">
        <li>
          <Link
            to="#"
            onClick={showSidebar}
            className="d-flex align-item-center"
          >
            <span className="icon">
              <BsIcons.BsList />
            </span>
            <span className="title">
              <img src={logo} alt="logo" className="img-fluid" width="150" />
            </span>
          </Link>
        </li>
      </ul>

      {currentUser && userInfo && (
        <div className="d-flex flex-column justify-content-center align-items-center py-3">
          <ProfileUrlModal>
            <Avatar active={active} />
            <p className="text-white mb-0 mt-2">@{userInfo?.alias}</p>
          </ProfileUrlModal>
        </div>
      )}

      <ul>
        <li>
          <NavLink to="/" exact activeClassName="active">
            <span className="icon">
              <BsIcons.BsHouseFill />
            </span>
            <span className="title">Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/streamers" exact activeClassName="active">
            <span className="icon">
              <BsIcons.BsFillPeopleFill />
            </span>
            <span className="title">Streamers</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/playToEarnGames" exact activeClassName="active">
            <span className="icon">
              <FaIcons.FaGamepad />
            </span>
            <span className="title">Play2Earn Games</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/marketplace" exact activeClassName="active">
            <span className="icon">
              <FaIcons.FaStore />
            </span>
            <span className="title">Marketplace</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/scholarships" exact activeClassName="active">
            <span className="icon">
              <FaIcons.FaGraduationCap />
            </span>
            <span className="title">Scholarships</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/esports" exact activeClassName="active">
            <span className="icon">
              <AiIcons.AiFillTrophy />
            </span>
            <span className="title">ESports</span>
          </NavLink>
        </li>

        {currentUser && userInfo?.onboarded && (
          <>
            <li>
              <NavLink to="/adCenter" exact activeClassName="active">
                <span className="icon">
                  <AiIcons.AiFillNotification />
                </span>
                <span className="title">AdCenter</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      <ul className="mt-auto">
        {currentUser && (
          <>
            {userInfo?.onboarded && (
              <>
                <li>
                  <NavLink to="/profile" exact activeClassName="active">
                    <span className="icon">
                      <BsIcons.BsPersonFill />
                    </span>
                    <span className="title">Profile</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/wallet" exact activeClassName="active">
                    <span className="icon">
                      <BsIcons.BsWallet />
                    </span>
                    <span className="title">Wallet</span>
                  </NavLink>
                </li>
              </>
            )}

            <li>
              <Link to="#" onClick={logout}>
                <span className="icon">
                  <BsIcons.BsBoxArrowInRight />
                </span>
                <span className="title">Sign Out</span>
              </Link>
            </li>
          </>
        )}

        {!currentUser && (
          <>
            <li>
              <NavLink to="/login" exact activeClassName="active">
                <span className="icon">
                  <BsIcons.BsBoxArrowInRight />
                </span>
                <span className="title">Login</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/signup" exact activeClassName="active">
                <span className="icon">
                  <BsIcons.BsPersonPlusFill />
                </span>
                <span className="title">Sign Up</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Navigation;
