import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import BgHeader from "../components/BgHeader";
import { useStreamers } from "../context/StreamersContext";
import logo from "../images/mango-ave-no-name.png";

const StreamersPage = () => {
  const { streamers, fetchStreamers } = useStreamers();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStreamers().finally(() => setLoading(false));
  }, []);

  return (
    <div id="streamers-page">
      <BgHeader />
      <div className="container">
        <div
          className="rounded p-3"
          style={{ backgroundColor: "#0f3460", marginTop: "-15vh" }}
        >
          <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center">
            <h3 className="mb-3 mb-md-0">Registered Streamers</h3>
            <div className="input-group streamers-page__search-input">
              <input
                type="text"
                className="form-control"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text"
                  style={{ backgroundColor: "#ff6712", color: "#fff" }}
                >
                  <BsSearch />
                </span>
              </div>
            </div>
          </div>

          {loading && (
            <div className="text-center mt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mb-0">Please wait..</p>
            </div>
          )}

          <div className="row mt-5">
            {!loading &&
              streamers
                .filter((item) => {
                  if (!item.onboarded) return null;

                  if (!searchText) return item;
                  else if (
                    item.firstname
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    item.lastname
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    item.alias.toLowerCase().includes(searchText.toLowerCase())
                  ) {
                    return item;
                  }

                  return null;
                })
                .map((streamer, index) => (
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={index}>
                    <Link
                      to={`/streamers/${streamer.id}`}
                      className="text-light streamers-page__streamer"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="p-3 d-flex flex-column justify-content-center align-items-center border rounded">
                        <img
                          src={streamer?.photoURL || logo}
                          alt={`photo${index}`}
                          className="img-fluid rounded-circle"
                          style={{ width: 75, height: 75 }}
                        />

                        <h6 className="mb-0">
                          {[streamer.firstname, streamer.lastname].join(" ")}
                        </h6>
                        {streamer?.alias && (
                          <p className="small mb-0">{`@${streamer?.alias}`}</p>
                        )}
                      </div>
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamersPage;
