import React, { useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { useAuth } from "../../context/AuthContext";
import { resendVerificationEmail } from "../../utils/helper";

const VerifyEmail = ({ auth, actionCode }) => {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [resending, setResending] = useState(false);

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  async function resendEmail() {
    try {
      const url = process.env.REACT_APP_ROOT_URL || "http://localhost:3000";

      setResending(true);
      await resendVerificationEmail({
        email: currentUser.email,
        displayName: currentUser.displayName,
        url: `${url}/emailHandler?mode=verifyEmail`,
      });
    } catch (error) {
      console.log("resendVerificationEmail-error: ", error);
    } finally {
      setResending(false);
    }
  }

  function handleVerifyEmail() {
    auth
      .applyActionCode(actionCode)
      .then((res) => {
        setIsError(false);
        // console.log("res", res);
      })
      .catch((error) => {
        console.log("error", error);
        setIsError(true);
      })
      .finally(() => setLoading(false));
  }

  if (loading) {
    return (
      <div className="container pt-5">
        <center>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </center>
      </div>
    );
  }

  if (isError) {
    return (
      <Layout>
        <h1 className="text-dark">Oops!</h1>
        <p className="text-danger">
          The action code is invalid. This can happen if the code is malformed,
          expired, or has already been used.
        </p>

        <button
          className="btn btn-primary"
          onClick={resendEmail}
          disabled={resending}
        >
          {resending ? "Sending.." : "Resend Email Verification Link"}
        </button>
      </Layout>
    );
  }

  return (
    <Layout>
      <center>
        <div
          className="bg-success rounded-circle d-flex justify-content-center align-items-center text-white"
          style={{ width: 60, height: 60 }}
        >
          <BsCheck style={{ fontSize: 50 }} />
        </div>
      </center>

      <div className="text-dark">
        <h1>Awesome!</h1>
        <p>Your account has been successfully verified!</p>
        <a href="/" className="btn btn-primary text-uppercase">
          get started
        </a>
      </div>
    </Layout>
  );
};

const Layout = ({ children }) => {
  return (
    <div className="container">
      <div className="w-100 mx-auto mt-5" style={{ maxWidth: 400 }}>
        <div className="bg-white rounded p-3 text-center">{children}</div>
      </div>
    </div>
  );
};

export default VerifyEmail;
