import React, { useRef } from "react";
import * as BsIcons from "react-icons/bs";
import { datalist } from "../../utils/dropdownList";

const BasicInfo = (props) => {
  const {
    firstname,
    lastname,
    birthdate,
    gender,
    interest,
    handleChange,
    setState,
    nextstep,
  } = props;

  const [isOthers, setIsOthers] = React.useState(false);
  const [othersField, setOthersField] = React.useState("");

  const birthdateRef = useRef();
  const interestRef = useRef();

  const handleAddInterest = () => {
    if (interestRef.current.value) {
      if (interestRef.current.value === "others") {
        setIsOthers(true);
      } else {
        setIsOthers(false);

        interestRef.current.classList.remove("is-invalid");
        const _interest = [...interest, interestRef.current.value];

        setState((prevState) => ({
          ...prevState,
          interest: _interest.filter(
            (item, index) => _interest.indexOf(item) === index
          ),
        }));
        interestRef.current.value = "";
      }
    }
  };
  const handleRemoveInterest = (item) => {
    const _interest = interest.filter((inter) => inter !== item);
    setState((prevState) => ({ ...prevState, interest: _interest }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (interest.length > 0) {
      if (getAge(birthdate)) {
        birthdateRef.current.classList.remove("is-invalid");
        nextstep();
      } else {
        birthdateRef.current.classList.add("is-invalid");
      }
    } else {
      interestRef.current.classList.add("is-invalid");
    }
  };

  function getAge(dob) {
    let validAge = false;
    if (dob) {
      const today = new Date();
      const dateOfBirth = new Date(dob);
      let age = today.getFullYear() - dateOfBirth.getFullYear();
      let dob_month = today.getMonth() - dateOfBirth.getMonth();
      let dob_date = today.getDate() - dateOfBirth.getDate();

      if (
        dob_month < 0 ||
        (dob_month === 0 && today.getDate() < dateOfBirth.getDate())
      ) {
        age--;
      }
      if (dob_month < 0) dob_month += 12;
      if (dob_date < 0) dob_date += 30;

      if (age >= 18 && age <= 100) validAge = true;
    }

    return validAge;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-3">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="firstname">Firstname</label>
            <input
              type="text"
              className="form-control"
              id="firstname"
              name="firstname"
              value={firstname || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="lastname">Lastname</label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              name="lastname"
              value={lastname || ""}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="birthdate">Birthdate</label>
            <input
              ref={birthdateRef}
              type="date"
              max="9999-12-31"
              className="form-control"
              id="birthdate"
              name="birthdate"
              value={birthdate || ""}
              onChange={handleChange}
              required
            />
            <div className="invalid-feedback">
              Invalid date of birth. Your age must be greater than 18
            </div>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="gender">Gender</label>
            <div id="gender" className="form-control">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  checked={gender === "male"}
                  onChange={handleChange}
                  required
                />
                <label className="form-check-label" htmlFor="male">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  checked={gender === "female"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="female">
                  Female
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="non-binary"
                  value="non-binary"
                  checked={gender === "non-binary"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="non-binary">
                  Non-binary
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div>
            <label htmlFor="interest">Interests</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text" id="basic-addon2">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="others"
                      checked={isOthers}
                      onChange={(e) => setIsOthers(e.target.checked)}
                    />
                    <label className="custom-control-label" htmlFor="others">
                      Others
                    </label>
                  </div>
                </div>
              </div>

              {isOthers ? (
                <>
                  <input
                    type="text"
                    className="form-control otherGame"
                    placeholder="Enter other interest and click + button."
                    value={othersField}
                    onChange={(e) => setOthersField(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        const _interest = [
                          ...interest,
                          othersField.toLowerCase(),
                        ];
                        setState((prevState) => ({
                          ...prevState,
                          interest: _interest.filter(
                            (item, index) => _interest.indexOf(item) === index
                          ),
                        }));

                        setOthersField("");
                      }}
                    >
                      <BsIcons.BsPlus />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <select
                    className="form-control"
                    id="interest"
                    ref={interestRef}
                    aria-describedby="interestHelp"
                  >
                    <option value="">Please select interest</option>
                    {datalist.interests.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleAddInterest}
                    >
                      <BsIcons.BsPlus />
                    </button>
                  </div>
                </>
              )}
            </div>
            <small id="interestHelp" className="form-text">
              Please click plus button to add interest (Gaming | Streaming)
            </small>
            <div className="invalid-feedback">Add interest atleast one.</div>
          </div>

          {interest && interest.length > 0 && (
            <div className="mt-3 border rounded p-2">
              <p className="small text-muted mb-1">
                List of selected interests
              </p>
              {interest.map((item) => (
                <div
                  className="badge badge-primary mr-2 mb-2 text-uppercase"
                  key={item}
                >
                  <div className="d-flex align-items-center p-1">
                    <span>{item}</span>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-light ml-2"
                      style={{ padding: "0.1rem 0.6rem" }}
                      onClick={() => handleRemoveInterest(item)}
                    >
                      <BsIcons.BsTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mt-5 d-flex justify-content-end">
        <button className="btn btn-primary text-uppercase">next</button>
      </div>
    </form>
  );
};

export default BasicInfo;
