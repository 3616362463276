import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import ProgressBar from "../components/progressBar/ProgressBar";
import { EmailPwButton } from "../components/AuthButtons/AuthButtons";

const SignupPage = () => {
  const [step, setStep] = useState(1);
  const [provider, setProvider] = useState("");

  const { signup, currentUser, setUserInfo } = useAuth();

  const history = useHistory();

  useEffect(() => {
    if (currentUser) history.push("/");
  }, [currentUser, history]);

  const actions = {
    setStep,
    setProvider,
    signup,
    history,
    provider,
    setUserInfo,
  };

  if (currentUser) return null;

  switch (step) {
    case 1:
      return (
        <SigupLayout step={step}>
          <Step1 {...actions} />
        </SigupLayout>
      );
    case 2:
      return (
        <SigupLayout step={step} setStep={setStep}>
          <Step2 {...actions} />
        </SigupLayout>
      );
    case 3:
      return (
        <SigupLayout step={step} setStep={setStep}>
          <Step3 {...actions} />
        </SigupLayout>
      );
    default:
      return null;
  }
};

const Step1 = ({ setProvider, setStep }) => {
  const handleProvider = (name) => {
    setProvider(name);
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <>
      <EmailPwButton
        className="btn btn-block"
        onClick={() => handleProvider("email")}
      />
    </>
  );
};

const Step2 = ({ setStep }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <>
      <div className="text-center mb-3">
        <img
          style={{ width: 80, maxWidth: "100%" }}
          src="https://cdn.battlefy.com/helix/images/gdpr/overview.svg"
          className="img-fluid"
          alt="shield"
        />
      </div>
      <p>
        First thing first... our community is important to us. Make sure you
        understand our Terms of Services.
      </p>
      <p>
        We value your personal data and you should too. Head to our friendly
        Policies portal.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            required
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            I agree to Terms of Service and Privacy Policy
          </label>
        </div>
        <button className="btn btn-primary btn-block mt-3">I Agree</button>
      </form>
    </>
  );
};

const Step3 = (props) => {
  const { signup, history, setUserInfo } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const emailRef = useRef();
  const displayNameRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setLoading(true);
      const user = await signup(
        emailRef.current.value,
        passwordRef.current.value
      );
      if (user)
        await user.user.updateProfile({
          displayName: displayNameRef.current.value,
        });
      // history.push("/");
    } catch (err) {
      console.log("err", err);
      setError(err.message || "Failed to create an account");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            ref={emailRef}
            type="email"
            className="form-control"
            id="email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Fullname</label>
          <input
            ref={displayNameRef}
            type="text"
            className="form-control"
            id="displayName"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            ref={passwordRef}
            type="password"
            className="form-control"
            id="password"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="passwordConfirm">Confirm Password</label>
          <input
            ref={passwordConfirmRef}
            type="password"
            className="form-control"
            id="passwordConfirm"
            required
          />
        </div>
        <button disabled={loading} className="mt-3 btn btn-block btn-primary">
          {loading ? "Creating an account.." : "Sign Up"}
        </button>
      </form>
    </>
  );
};

const SigupLayout = ({ step, setStep, children }) => {
  return (
    <div className="container pt-5">
      <div
        className="w-100 p-3 bg-white rounded text-dark"
        style={{ maxWidth: 400, margin: "0 auto" }}
      >
        <TitleBar step={step} />
        <div>{children}</div>

        <div className="d-flex align-items-center mt-3">
          {setStep && (
            <button
              className="btn btn-outline-dark mr-3"
              onClick={() => setStep((prevStep) => prevStep - 1)}
            >
              <BsIcons.BsArrowLeft />
              Back
            </button>
          )}
          <p className="m-0 text-center">
            Already have an account? <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const TitleBar = ({ step }) => {
  const showTitle = (step) => {
    switch (step) {
      case 1:
        return <h6 className="text-center">Create your MangoAve account</h6>;
      case 2:
        return <h6 className="text-center">Our Policies</h6>;
      case 3:
        return <h6 className="text-center">Sign up with Email</h6>;
      default:
        return null;
    }
  };

  const stepPercent = (step / 3) * 100;

  return (
    <>
      {showTitle(step)}
      <ProgressBar stepPercent={stepPercent} />
    </>
  );
};
export default SignupPage;
