import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const LogoutPage = () => {
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function handleLogout() {
      setError("");

      try {
        await logout();
        history.push("/login");
      } catch {
        setError("Failed to log out");
      }
    }
    handleLogout();
  }, [history, logout]);

  return (
    <>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
    </>
  );
};

export default LogoutPage;
