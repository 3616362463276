import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import styled from "styled-components";
import ProfileUrlModal from "../components/Modal/ProfileUrlModal";
import useWindowSize from "../hooks/useWindowSize";
import UpdateBasicInfo from "../components/UpdateProfile/UpdateBasicInfo";
import UpdatePaymentInfo from "../components/UpdateProfile/UpdatePaymentInfo";
import UpdateStreamInfo from "../components/UpdateProfile/UpdateStreamInfo";
import BgHeader from "../components/BgHeader";
import logo from "../images/mango-ave-no-name.png";

const ProfilePage = () => {
  const { userInfo } = useAuth();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 360;
  // console.log(userInfo);

  const [active, setActive] = useState(0);

  const formatTextInfo = (text, prefix) => {
    if (text) return prefix ? prefix + text : text;
    return "N/A";
  };

  const displayTab = (tab) => {
    switch (tab) {
      case 0:
        return <BasicInfo {...userInfo} formatTextInfo={formatTextInfo} />;
      case 1:
        return <StreamInfo {...userInfo} formatTextInfo={formatTextInfo} />;
      case 2:
        return <PaymentInfo {...userInfo} formatTextInfo={formatTextInfo} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <BgHeader />

      <div className="container" style={{ marginTop: isMobile ? -50 : -100 }}>
        <div className="d-flex align-items-end">
          <Avatar img={userInfo?.photoURL} isMobile={isMobile} />
          <div className="ml-3">
            <ProfileUrlModal />
          </div>
        </div>

        <ul className="nav mt-3">
          <li
            className="nav-item"
            style={{
              borderBottom: active === 0 ? "2px solid #e94560" : "",
            }}
          >
            <Link
              to="#"
              onClick={() => setActive(0)}
              className="nav-link text-white"
            >
              Basic
            </Link>
          </li>
          <li
            className="nav-item"
            style={{ borderBottom: active === 1 ? "2px solid #e94560" : "" }}
          >
            <Link
              to="#"
              onClick={() => setActive(1)}
              className="nav-link text-white"
            >
              Stream
            </Link>
          </li>
          <li
            className="nav-item"
            style={{ borderBottom: active === 2 ? "2px solid #e94560" : "" }}
          >
            <Link
              to="#"
              onClick={() => setActive(2)}
              className="nav-link text-white"
            >
              Payment
            </Link>
          </li>
        </ul>
        <div
          className="mt-3 p-3 rounded"
          style={{ backgroundColor: "#16213e" }}
        >
          {displayTab(active)}
        </div>
      </div>
    </div>
  );
};

const BasicInfo = ({
  firstname,
  lastname,
  address,
  email,
  phone,
  formatTextInfo,
}) => {
  const [isUpdate, setIsUpdate] = useState(false);

  if (isUpdate) return <UpdateBasicInfo setIsUpdate={setIsUpdate} />;

  return (
    <div>
      <div className="text-right">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => setIsUpdate(true)}
        >
          Update
        </button>
      </div>

      <div className="row">
        <div className="col-md-6">
          <p>
            <small className="text-muted">First Name: </small>
            <br />
            <span>{formatTextInfo(firstname)}</span>
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <small className="text-muted">Last Name: </small>
            <br />
            <span>{formatTextInfo(lastname)}</span>
          </p>
        </div>
      </div>

      <p>
        <small className="text-muted">Address: </small>
        <br />
        <span>{formatTextInfo(address)}</span>
      </p>

      <div className="row">
        <div className="col-md-6">
          <p>
            <small className="text-muted">Email: </small>
            <br />
            <span>{formatTextInfo(email)}</span>
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <small className="text-muted">Phone: </small>
            <br />
            <span>{formatTextInfo(phone)}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const StreamInfo = ({
  alias,
  streamChannelUrl,
  followerCount,
  contentStream,
  gamesPlaying,
  products,
  entertainment,
  streamingPlatforms,
  interest,
  formatTextInfo,
}) => {
  const [isUpdate, setIsUpdate] = useState(false);

  if (isUpdate) return <UpdateStreamInfo setIsUpdate={setIsUpdate} />;

  return (
    <div>
      <div className="text-right">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => setIsUpdate(true)}
        >
          Update
        </button>
      </div>

      <div className="row">
        <div className="col-md-6">
          <p>
            <small className="text-muted">Streamer Name: </small>
            <br />
            <span>{formatTextInfo(alias)}</span>
          </p>
          <p>
            <small className="text-muted">Streamer Channel Name: </small>
            <br />
            <span>{formatTextInfo(streamChannelUrl)}</span>
          </p>
          <p>
            <small className="text-muted">Channel Follower Count: </small>
            <br />
            <span>{formatTextInfo(followerCount)}</span>
          </p>
          <div>
            <small className="text-muted">Platforms: </small>
            <p>{streamingPlatforms.join(", ")}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <small className="text-muted">Interests: </small>
            <p>{interest.join(", ")}</p>
          </div>
          <p>
            <small className="text-muted">Stream Content: </small>
            <br />
            <span>{formatTextInfo(contentStream)}</span>
          </p>
          {contentStream === "Games" && (
            <div>
              <small className="text-muted">Games Playing: </small>
              <p>{gamesPlaying.join(", ")}</p>
            </div>
          )}
          {contentStream === "Online Selling" && (
            <div>
              <small className="text-muted">Products: </small>
              <p>{products.join(", ")}</p>
            </div>
          )}
          {contentStream === "Entertainment" && (
            <div>
              <small className="text-muted">Entertainment: </small>
              <p>{entertainment.join(", ")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PaymentInfo = ({ payment, formatTextInfo }) => {
  const [isUpdate, setIsUpdate] = useState(false);

  if (isUpdate) return <UpdatePaymentInfo setIsUpdate={setIsUpdate} />;

  return (
    <div>
      <div className="text-right">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => setIsUpdate(true)}
        >
          Update
        </button>
      </div>

      <div>
        <p>
          <small className="text-muted">Paypal Account: </small>
          <br />
          <span>{formatTextInfo(payment.paypal)}</span>
        </p>
        <p>
          <small className="text-muted">Gcash Account: </small>
          <br />
          <span>{formatTextInfo(payment.gcash)}</span>
        </p>
        <p>
          <small className="text-muted">Coins.ph: </small>
          <br />
          <span>{formatTextInfo(payment.coins_ph)}</span>
        </p>
      </div>
    </div>
  );
};

const Avatar = ({ img, isMobile }) => {
  return (
    <AvatarWrapper
      src={img || logo}
      alt="Avatar"
      isMobile={isMobile}
      className="bg-secondary"
    />
  );
};

const AvatarWrapper = styled.img`
  vertical-align: middle;
  width: ${(props) => (props.isMobile ? "100px" : "150px")};
  height: ${(props) => (props.isMobile ? "100px" : "150px")};
  border: 2px solid #fff;
  padding: 5px;
`;

export default ProfilePage;
