export const datalist = {
  interests: ["tech", "fashion", "travel", "food", "gaming", "cars"],
  products: ["food", "shoes", "bags", "apparel", "jewelry", "electronics"],
  entertainments: [
    "StoryTelling",
    "Singing/Karaoke",
    "Dancing",
    "Instrument playing",
  ],
  content_streaming: [
    { title: "Game-Valorant", value: "Game-Valorant" },
    { title: "Game-Axie", value: "Game-Axie" },
    { title: "Game-Mobile Legend", value: "Game-Mobile Legend" },
    { title: "Game-Counter Strike", value: "Game-Counter Strike" },
    { title: "Game-Others", value: "Game-Others" },
    { title: "Online Selling-Bags", value: "Online Selling-Bags" },
    { title: "Online Selling-Shoes", value: "Online Selling-Shoes" },
    { title: "Online Selling-Apparels", value: "Online Selling-Apparels" },
    { title: "Online-Selling-Others", value: "Online-Selling-Others" },
    { title: "Entertainment-Singing", value: "Entertainment-Singing" },
    { title: "Entertainment-Dancing", value: "Entertainment-Dancing" },
    { title: "Entertainment-Instrument", value: "Entertainment-Instrument" },
  ],
};
