import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";

import stream1 from "../images/stream/stream1.png";
import stream2 from "../images/stream/stream2.png";
import stream3 from "../images/stream/stream3.png";
import stream4 from "../images/stream/stream4.png";
import stream5 from "../images/stream/stream5.png";
import game1 from "../images/game1.jpg";
import game2 from "../images/game2.jpg";
import game3 from "../images/game3.jpg";
import OnboardingPage from "./OnboardingPage";

import paper_airplane from "../images/paper_airplane.png";
import CarouselContainer from "../components/Carousel/CarouselContainer";

import { resendVerificationEmail } from "../utils/helper";

const LandingPage = () => {
  const { loading, currentUser, userInfo } = useAuth();

  if (!loading) {
    if (currentUser) {
      if (!currentUser.emailVerified)
        return (
          <VerifyEmail
            {...currentUser}
            resendVerificationEmail={resendVerificationEmail}
          />
        );
      else if (!userInfo?.onboarded) return <OnboardingPage />;
    }

    return (
      <div>
        <CarouselContainer />

        <section className="container pt-5">
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
            <h3>Featured Streams</h3>
            {/* <button className="btn btn-outline-primary">Browse Streams</button> */}
          </div>
          <div className="row mt-3">
            <div className="col-md-4 mb-3">
              <a href="https://fb.watch/cnwq2hUCwA/" target="_blank">
                <img src={stream1} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href="https://fb.watch/cnwFzEYDKo/" target="_blank">
                <img src={stream2} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href="https://fb.watch/cnwKTVBkIf/" target="_blank">
                <img src={stream3} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href="https://fb.watch/cnxlU2zqxo/" target="_blank">
                <img src={stream4} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href="https://fb.watch/cny1FWjdCV/" target="_blank">
                <img src={stream5} alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        </section>

        <section className="container pt-5">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Popular Games</h3>
          </div>
          <div className="row mt-3">
            <div className="col-md-4 mb-3">
              <a href="https://playvalorant.com/en-us/" target="_blank">
                <img src={game1} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href="https://www.callofduty.com/mobile" target="_blank">
                <img src={game2} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-md-4 mb-3">
              <a href="https://m.mobilelegends.com/en" target="_blank">
                <img src={game3} alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }

  return null;
};

const VerifyEmail = (props) => {
  const [resending, setResending] = useState(false);
  const resendEmail = async () => {
    try {
      const url = process.env.REACT_APP_ROOT_URL || "http://localhost:3000";

      setResending(true);
      await props.resendVerificationEmail({
        email: props.email,
        displayName: props.displayName,
        url: `${url}/emailHandler?mode=verifyEmail`,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setResending(false);
    }
  };

  return (
    <div className="container d-flex justify-content-center">
      <div
        className="w-100 bg-light text-dark p-3 text-center mt-5"
        style={{ maxWidth: 400 }}
      >
        <img
          src={paper_airplane}
          alt="paper airplane"
          className="img-fluid w-100"
          style={{ maxWidth: 250 }}
        />
        <h4 className="mb-3 mt-3">Almost there!</h4>
        <p>A verification email has been sent to</p>
        <h5 className="mb-3">{props.email}</h5>
        <p>
          Please check your email and click on the <br />
          verification link to get started.
        </p>
        <button
          className="mt-3 btn btn-primary text-uppercase"
          onClick={resendEmail}
          disabled={resending}
        >
          {resending ? "sending.." : "resend email"}
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
