import React from "react";

import ArkarusLogo from "../images/nft/arkarus.jpg";
import ChampionHuntersLogo from "../images/nft/championHunters.png";
import FaithOriginLogo from "../images/nft/fateOrigin.jpg";
import JungleRoadLogo from "../images/nft/jungleRoad.jpg";
import AxieLogo from "../images/nft/axie.png";
import ThetanArenaLogo from "../images/nft/thetanArena.png";
import TinyColonyLogo from "../images/nft/tiny_colony.jpeg";
import MoblandLogo from "../images/nft/mobland.jpeg";
import MiniRoyaleLogo from "../images/nft/miniRoyale.jpg";
import DevikinsLogo from "../images/nft/devikins.jpg";

const PlayToEarnGames = () => {
  const nft = [
    { img: ArkarusLogo, title: "Arkarus", link: "https://arkarus.com/" },
    {
      img: ChampionHuntersLogo,
      title: "Champion Hunters",
      link: "https://championhuntersgame.com/",
    },
    {
      img: FaithOriginLogo,
      title: "Faith Origin",
      link: "https://fateorigin.com/",
    },
    {
      img: JungleRoadLogo,
      title: "Jungle Road",
      link: "https://www.jungleroad.io/",
    },
    {
      img: AxieLogo,
      title: "Axie Infinity",
      link: "https://axieinfinity.com/",
    },
    {
      img: ThetanArenaLogo,
      title: "Thetan Arena",
      link: "https://thetanarena.com/",
    },
    {
      img: TinyColonyLogo,
      title: "Tiny Colony",
      link: "https://tinycolony.io/tinyverse",
    },
    {
      img: MoblandLogo,
      title: "Mobland",
      link: "https://mob.land/",
    },
    {
      img: MiniRoyaleLogo,
      title: "Mini Royale",
      link: "https://miniroyale.io/",
    },
    {
      img: DevikinsLogo,
      title: "Devikins",
      link: "https://devikins.com/",
    },
  ];

  return (
    <div className="container mt-5">
      <h2 className="mb-3">Play To Earn Games</h2>
      <div className="row">
        {nft.map((el, i) => {
          const styles = {
            heroImg: {
              background: `url(${el.img}) no-repeat center / cover`,
              width: "100%",
              minHeight: 250,
              borderRadius: 10,
              position: "relative",
              transition: "transform .25s ease",
            },
            content: {
              position: "absolute",
              bottom: 0,
              background: "rgb(0, 0, 0)",
              background: "rgba(0, 0, 0, 0.5)",
              color: "#f1f1f1",
              width: "100%",
              padding: "10px 20px",
              borderRadius: "0 0 10px 10px",
            },
          };
          return (
            <div className="col-md-3 mb-3" key={i}>
              <a href={el.link} target="_blank">
                <div className="nft" style={styles.heroImg}>
                  <div style={styles.content}>
                    <h5 className="mb-0">{el.title}</h5>
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PlayToEarnGames;
